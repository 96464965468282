import _ from 'lodash'
import React from 'react'
import {string, arrayOf} from 'prop-types'
import {Link} from 'gatsby'
import glamorous from 'glamorous'

import {colors} from '../css/themes'
import {Span, Date, LinkStyled} from './common'

const styleIcon = {
  display: 'inline-block',
  marginRight: '0.5rem',
}

const styleIconItem = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginRight: '2rem',
  color: colors.darkGrey,
}

const styleIconRow = {
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  marginBottom: '0.75rem',
}

const styleDelimeter = {
  marginLeft: '0.2rem',
  marginRight: '0.2rem',
}

const IconRow = glamorous.div(styleIconRow)
const IconItem = glamorous.div(styleIconItem)

const joinLinks = (strings, renderLinks, queryPrefix, queryKey, delimeter) =>
  _.chain(strings)
    .map((string, i) => (
      <React.Fragment key={string}>
        {renderLinks ? (
          <LinkStyled>
            <Link to={`${queryPrefix}?${queryKey}=${string}`}>{string}</Link>{' '}
          </LinkStyled>
        ) : (
          <Span>{string}</Span>
        )}
      </React.Fragment>
    ))
    .reduce((prev, curr, i) => [
      prev,
      <Span key={i} css={styleDelimeter}>
        {`${delimeter}`}
      </Span>,
      curr,
    ])
    .value()

const PostMeta = ({queryPrefix, date, tags, locations, renderLinks}) => (
  <IconRow>
    <IconItem>
      {/* <glamorous.I className={'far fa-calendar'} css={styleIcon} /> */}
      <Date dateString={date} />
    </IconItem>
    {tags &&
      tags.length && (
        <IconItem>
          {/* <glamorous.I className={'fas fa-tag'} css={styleIcon} /> */}
          {joinLinks(tags, renderLinks, queryPrefix, 'tag', '·')}
        </IconItem>
      )}
    {locations &&
      locations.length && (
        <IconItem>
          {/* <glamorous.I className={'fas fa-map-marker-alt'} css={styleIcon} /> */}
          {locations.join(' · ')}
        </IconItem>
      )}
  </IconRow>
)

PostMeta.propTypes = {
  date: string.isRequired,
  tags: arrayOf(string),
  locations: arrayOf(string),
  queryPrefix: string.isRequired,
}

export default PostMeta
