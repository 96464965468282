import _ from 'lodash'
import React from 'react'
import {string, arrayOf} from 'prop-types'
import {Link} from 'gatsby'
import glamorous from 'glamorous'

import {H4, P, LinkStyled} from './common'
import Image from '../components/image'
import PostMeta from './post-meta'

const styleTitle = {
  marginBottom: '0.5rem',
}
const styleShort = {
  marginTop: '0.5rem',
  lineHeight: '1.3rem',
}

const PreviewImageWrapper = glamorous.div({
  display: 'flex',
  flexDirection: 'row',
})

const PostWrapper = glamorous.div(
  {
    marginLeft: '2rem',
  },
  ({featuredImage}) => {
    const hasPreviewImage = _.has(featuredImage, 'childImageSharp.fixed')
    if (!hasPreviewImage) return {marginLeft: '0rem'}
  }
)

const TitleWrapper = glamorous.div({
  marginTop: '0.5rem',
  marginBottom: '1.5rem',
})

const PostPreview = ({
  path,
  featuredImage,
  title,
  date,
  tags,
  locations,
  short,
  renderLinks,
  queryPrefix,
}) => {
  const hasPreviewImage = _.has(featuredImage, 'childImageSharp.fixed')
  return (
    <LinkStyled
      css={{
        '& a': {
          textDecoration: 'none',
          borderBottom: 'none',
        },
      }}
    >
      <Link to={path}>
        <PreviewImageWrapper>
          {hasPreviewImage && <Image fixed={featuredImage.childImageSharp.fixed} />}
          <PostWrapper featuredImage={featuredImage}>
            <TitleWrapper>
              <H4 css={styleTitle}>{title}</H4>
              <PostMeta queryPrefix={queryPrefix} date={date} tags={tags} renderLinks={renderLinks} />
            </TitleWrapper>
            {short && <P css={styleShort}>{short}</P>}
          </PostWrapper>
        </PreviewImageWrapper>
      </Link>
    </LinkStyled>
  )
}

PostPreview.propTypes = {
  path: string.isRequired,
  title: string.isRequired,
  date: string.isRequired,
  tags: arrayOf(string),
  locations: arrayOf(string),
  short: string,
  queryPrefix: string.isRequired,
}

export default PostPreview
