import React from 'react'
import {string, shape, any} from 'prop-types'
import {Link} from 'gatsby'
import glamorous from 'glamorous'

import CardHover from '../components/card-hover'
import Image from '../components/image'
import {LinkStyled, Strong} from '../components/common'

/*
 * 1. Book preview
 * 2. Podcast Preview
 * 3. Film Preview
 *
 * All of these have some sort of image with a subtitle description
 */

const MediaPreviewContentWrapper = glamorous.div({
  display: 'flex',
  marginBottom: '2rem',
  flexDirection: 'column',
  flexGrow: 1,
  alignItems: 'center',
  textAlign: 'center',
})

const MediaPreviewDescription = glamorous.div({
  marginTop: '0.15rem',
  fontSize: '0.85rem',
})

const MediaPreview = ({path, image, title, author}) => {
  return (
    <CardHover style={{display: 'flex', flexBasis: '30%'}}>
      <LinkStyled css={{width: '100%'}}>
        <Link to={path}>
          <MediaPreviewContentWrapper>
            {image && <Image fixed={image.childImageSharp.fixed} />}
            <MediaPreviewDescription>
              <Strong>{title}</Strong>
            </MediaPreviewDescription>
          </MediaPreviewContentWrapper>
        </Link>
      </LinkStyled>
    </CardHover>
  )
}

MediaPreview.propTypes = {
  path: string,
  image: shape({
    childImageSharp: shape({
      fixed: any,
    }).isRequired,
  }).isRequired,
  title: string.isRequired,
  author: string.isRequired,
}

export default MediaPreview
