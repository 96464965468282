import _ from 'lodash'
import React from 'react'
import {StaticQuery, graphql} from 'gatsby'
import glamorous from 'glamorous'

import {mediaMaxWidth, sm} from '../css/themes'
import CardHover from './card-hover'
import {A, LinkStyled} from './common'

const SocialWrapper = glamorous.div({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  [mediaMaxWidth(sm)]: {
    flexDirection: 'column',
  },
})

const styleSocialIcons = {
  marginRight: '0.5rem',
  fontSize: '1.2rem',
}

const Social = ({data}) => {
  const {
    site: {
      siteMetadata: {profiles},
    },
  } = data

  const profilesComponents = _.map(profiles, ({username, url, icon}) => {
    return (
      <CardHover key={url}>
        <LinkStyled
          css={{
            '& a': {
              textDecoration: 'none',
              borderBottom: 'none',
              '&:hover': {
                '&::before': {
                  visibility: 'hidden',
                },
              },
            },
          }}
        >
          <A href={url} rel="noopener noreferrer">
            <glamorous.I className={icon} css={styleSocialIcons} />
            {username}
          </A>
        </LinkStyled>
      </CardHover>
    )
  })

  return <SocialWrapper>{profilesComponents}</SocialWrapper>
}

const SocialQuery = () => (
  <StaticQuery
    query={graphql`
      query SiteProfilesQuery {
        site {
          siteMetadata {
            profiles {
              keybase {
                username
                url
                icon
              }
              github {
                username
                url
                icon
              }
              twitter {
                username
                url
                icon
              }
              linkedin {
                username
                url
                icon
              }
            }
          }
        }
      }
    `}
    render={data => <Social data={data} />}
  />
)

export default SocialQuery
