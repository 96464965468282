import React from 'react'
import glamorous from 'glamorous'
import {colors, hexToRgba} from '../css/themes'

const Hover = glamorous.div({
  position: 'relative',
  paddingLeft: '1rem',
  paddingRight: '1rem',
  margin: '0.5rem',
  '&:hover .hover-backdrop': {
    cursor: 'pointer',
    opacity: 0.18,
    transform: 'none',
  },
})

const HoverBackdrop = glamorous.div({
  position: 'absolute',
  pointerEvents: 'none',
  top: '-4px',
  bottom: '-4px',
  left: '-4px',
  right: '-4px',
  background: colors.orange,
  boxShadow: `0 0 40px 3px ${hexToRgba(colors.black, 0.4)}`,
  borderRadius: '8px',
  transition: 'all 320ms ease-out',
  transform: 'scale(1.03)',
  opacity: 0,
})

const CardHover = ({style, children}) => (
  <Hover css={style}>
    <HoverBackdrop className="hover-backdrop" />
    {children}
  </Hover>
)

export default CardHover
