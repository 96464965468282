import React from 'react'
import {any, string} from 'prop-types'
import Img from 'gatsby-image'
import glamorous from 'glamorous'

import {getThemeStyles} from '../css/themes'

const ImageWrapper = glamorous.span({
  display: 'block',
  marginTop: '2rem',
  marginBottom: '2rem',
})
const Caption = glamorous.i(
  {
    marginTop: '0.25rem',
    marginBottom: '0.25rem',
    fontSize: '0.9rem',
    fontWeight: 'normal',
  },
  getThemeStyles('i')
)
const Image = ({fluid, fixed, caption}) => (
  <ImageWrapper>
    <Img fluid={fluid} fixed={fixed} />
    {caption && <Caption>{caption}</Caption>}
  </ImageWrapper>
)
Image.propTypes = {
  fixed: any,
  fluid: any,
  caption: string,
}

export default Image
