import _ from 'lodash'
import React from 'react'
import {Link, graphql} from 'gatsby'
import glamorous from 'glamorous'

/* eslint-disable-next-line import/no-named-as-default */
import Default from '../layouts/default'
import {mediaMaxWidth, mediaMinWidth, sm, md} from '../css/themes'
import CardHover from '../components/card-hover'
import PostPreview from '../components/post-preview'
import MediaPreview from '../components/media-preview'
import Profiles from '../components/profiles'
import {Avatar, H1, H4, P, I, LinkStyled} from '../components/common'

const styleRecentWritingWrapper = {
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  [mediaMaxWidth(sm)]: {
    flexDirection: 'column',
  },
}

const stylePostPreview = {
  display: 'flex',
  [mediaMinWidth(md)]: {
    flexBasis: '30%',
  },
}

const AvatarWrapper = glamorous.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '1rem',
  marginBottom: '2rem',
})

const RecentWritingWrapper = glamorous.div(styleRecentWritingWrapper)

const MediaPreviewWrapper = glamorous.div({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  [mediaMaxWidth(sm)]: {
    justifyContent: 'center',
  },
})

const RecentWriting = ({data}) =>
  _.map(data.allMarkdownRemark.edges, ({node: {frontmatter: {path, title, date}}}) => (
    <glamorous.Div key={path} css={stylePostPreview}>
      <CardHover>
        <PostPreview path={`/writing${path}`} title={title} date={date} queryPrefix="/writing" />
      </CardHover>
    </glamorous.Div>
  ))

const renderMediaPreviews = edges => (
  <MediaPreviewWrapper>
    {_.map(edges, ({node: {image, title, author, review, fields: {path}}}) => {
      return (
        <MediaPreview key={title} title={title} author={author} review={review} path={path} image={image} />
      )
    })}
  </MediaPreviewWrapper>
)

const IndexPage = ({data, location}) => (
  <Default location={location}>
    <AvatarWrapper>
      <Avatar src={data.allFile.edges[0].node.publicURL} size={70} css={{marginRight: '1rem'}} />
      <H1>Hi, I'm Jacob 🍜</H1>
    </AvatarWrapper>
    <P>
      I'm a frontend developer, hackathon organizer, and senior Computer Science student at the University of
      California, Davis. I do some{` `}
      <LinkStyled>
        <Link to="/writing">writing</Link>
      </LinkStyled>
      {` `}
      about correctness, frontend, and functional programming. I also maintain a collection of book, podcast,
      and film{` `}
      <LinkStyled>
        <Link to="/reviews">reviews</Link>
      </LinkStyled>
      .{` `}
      If you're interested, this is where I am and what I'm working on right {` `}
      <LinkStyled>
        <Link to="/now">now</Link>
      </LinkStyled>
      .{` `}
      Here is my résumé and a bit {` `}
      <LinkStyled>
        <Link to="/about">about</Link>
      </LinkStyled>
      {` `} me.
    </P>

    <H4>Recent Writing</H4>

    <RecentWritingWrapper>
      <RecentWriting data={data} />
    </RecentWritingWrapper>

    <H4>Find Me Places</H4>
    <P>
      <I>Fun fact, I've cryptographically proven that I own these profiles using Keybase.</I>
    </P>
    <Profiles />
  </Default>
)

export default IndexPage

export const pageQuery = graphql`
  query AvatarQuery {
    allFile(filter: {name: {eq: "avatar"}}) {
      edges {
        node {
          publicURL
        }
      }
    }
    allMarkdownRemark(
      limit: 3
      sort: {order: DESC, fields: [frontmatter___date]}
      filter: {fields: {type: {eq: "writing"}}}
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            path
            title
            tags
            locations
            date(formatString: "YYYY-MM-DD")
          }
          headings {
            value
          }
        }
      }
    }
  }
`
